<template>
  <ExaminationHeader :isLight="false" />
  <div class="error-content">
    <h2 class="error-content__title">
      {{ $t('base.forbidden') }}
    </h2>
  </div>
</template>

<script>
import ExaminationHeader from '@/components/Commons/Examination/ExaminationHeader.vue';
import BaseRedirectButton from '../components/Base/BaseRedirectButton.vue';
export default {
  name: 'Forbidden',
  components: { BaseRedirectButton, ExaminationHeader }
};
</script>

<style scoped lang="scss">
.header {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .logo-pixel {
    align-self: flex-end;
  }
}
.error-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    margin: 25px 0 10px 0;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }
}
</style>
